import propTypes from "prop-types";
import "./contestsCardLink.scss";
import { formatDate, truncateWords } from "utils/helper";

const ContestsCardLink = ({ contest = {}, clickHandler }) => {
  const handleClick = () => {
    clickHandler(contest);
  };
  return (
    <div onClick={handleClick} className="contestCard__container shadow-md p-4">
      <div className="contestImg__holder">
        <img src={contest?.coverPhoto?.url} alt="img" />
      </div>
      <p className="contest-title">{truncateWords(contest.title, 130)}</p>
      <div>
        <span className="text-sm">{formatDate(contest.startDate)}</span> -{" "}
        <span className="text-sm">{formatDate(contest.endDate)}</span>
      </div>
    </div>
  );
};

ContestsCardLink.propTypes = {
  contest: propTypes?.object,
  clickHandler: propTypes.func,
};
export default ContestsCardLink;
