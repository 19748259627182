import propTypes from "prop-types";
import "./fileUpload.css";
import { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { uploadFile } from "services/files.service";
// import Image from "../IMAGE/Image";
import { IoCloudUploadOutline } from "react-icons/io5";

const FileUpload = ({
  label = "Upload File",
  onChange,
  name,
  src,
  errorLabel,
  allowedFiles,
  size = "10485760",
  value,
  reuired,
}) => {
  const [fileName, setFileName] = useState("");
  const [validationMessage, setValidationMessage] = useState(errorLabel);
  // const [srcState, setSrcState] = useState(src);
  // const [errored, setErrored] = useState(false);
  const [imgSrc, setImgSrc] = useState(value);
  const fileInput = useRef();

  useEffect(() => {
    if (!imgSrc) setImgSrc(src);
  }, [src, imgSrc]);

  const handleFileBrowse = () => {
    fileInput.current.click();
  };

  const { mutate: uploadFileMutate, isLoading: uploading } = useMutation(
    async (formData) => await uploadFile(formData),
    {
      onSuccess(data) {
        const uploadedFile = {
          name: fileName,
          size: data?.data?.size,
          url: data.data.url,
          id: data.data.id,
          type: data?.data?.type,
        };
        onChange(name, uploadedFile);
        toast.success(data.message);
      },

      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleSetFileName = (name) => {
    setFileName(name);
  };
  // const handleSetFilePreview = (file) => {
  //   setImgSrc(URL.createObjectURL(file));
  // };
  // max 10mb
  const isValidFileSize = (fileSize) => {
    if (fileSize > Number(size)) {
      setValidationMessage("File size must be 10mb max");
      return false;
    }
    setValidationMessage("");
    return true;
  };

  // allowed file type: ["pdf", "txt", "docx", "xlsx"]
  const isValidFileType = (file) => {
    const allowedExtensions = allowedFiles
      ? [allowedFiles]
      : ["png", "jpg", "jpeg", "ppt", "bmp", "pdf"];
    const fileExtension = file.type.split("/")[1];

    if (!allowedExtensions.includes(fileExtension)) {
      setValidationMessage(
        `required file type is any of: ${allowedExtensions.toLocaleString()}`
      );

      return false;
    }
    setValidationMessage("");
    return true;
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];

    if (isValidFileSize(file.size) && isValidFileType(file)) {
      handleSetFileName(file.name);
      const formData = new FormData();
      formData.append("file", file);
      uploadFileMutate(formData);
    }
  };
  // const handleOnError = () => {
  //   if (!errored) {
  //     setImgSrc(fallbackSrc);
  //     setErrored(true);
  //   }
  // };
  return (
    <div className="upload-file">
      <div>
        <div className="file-input-ctn" onClick={handleFileBrowse}>
          <div className="photo">{fileName}</div>
          <div className="headshot-btn-ctn">
            <div
              type="button"
              className="browse-btn  flex flex-col items-center"
            >
              <IoCloudUploadOutline size={30} />
              <span>{uploading ? "Uploading file...please wait!" : label}</span>
              <p className="text-xs font-body text-faded top-3 opacity-30">
                png, jpg, jpeg, ppt, bmp, or pdf (10mb max){" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <span> {fileName} </span> */}
      <div className="form-input-ctn">
        <input
          type="file"
          // accept="image/*"
          className="file-input"
          onChange={handleFileSelect}
          ref={fileInput}
          required={reuired}
        />
        {errorLabel ? (
          <span className="error-label"> {errorLabel} </span>
        ) : null}
      </div>

      <label
        className={`label error-msg text-red-500 ${
          validationMessage && "error-msg-visible"
        }`}
      >
        {validationMessage}
      </label>
    </div>
  );
};

export default FileUpload;
FileUpload.propTypes = {
  label: propTypes.string,
  uploadHandler: propTypes.func,
  name: propTypes.string,
  errorLabel: propTypes.string,
  src: propTypes.string,
  allowedFiles: propTypes.string,
  size: propTypes.any,
  fallbackSrc: propTypes.any,
};
