export const LANDING_ROUTE = "/";
export const HOME_ROUTE = "/";
export const ABOUT_US_ROUTE = "about-us";
export const CONTACT_US_ROUTE = "contact-us";
export const LATEST_UPDATES_ROUTE = "latest-updates";
export const VIDEOS_ROUTE = "videos";
export const VIDEOSPHASE_ROUTE = "videos-phase";
export const VIEWALLVIDEOS_ROUTE = "view-all-vidoes";
export const CONTEST_ROUTE = "contest";
export const TERMS_AND_CONDTIONS_ROUTE = "/terms-and-conditions";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const LOGIN_ROUTE = "/login";
export const CONTESTANT_SIGNUOP_ROUTE = "/contestant-signup";
export const VOTER_SIGNUOP_ROUTE = "/voter-signup";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const VERIFICATION_ROUTE = "/verification";

export const LEGAL_NOTICE_ROUTE = "/legal-notice";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const COOKIE_POLICY_ROUTE = "/cookie-policy";

//for the mean time
export const VIEW_SINGLE_CONTEST = "/contests/:id/view";
