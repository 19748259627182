import React, { lazy } from "react";
import {
  HOME_ROUTE,
  ABOUT_US_ROUTE,
  LATEST_UPDATES_ROUTE,
  CONTACT_US_ROUTE,
  CONTEST_ROUTE,
  VIDEOS_ROUTE,
  VIEW_SINGLE_CONTEST,
  TERMS_AND_CONDTIONS_ROUTE,
  LANDING_ROUTE,
  RESET_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  VOTER_SIGNUOP_ROUTE,
  CONTESTANT_SIGNUOP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  VERIFICATION_ROUTE,
  LEGAL_NOTICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  COOKIE_POLICY_ROUTE,
} from "constants/urls/Landing";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import("pages/landing/home/Home"));
const AboutUs = lazy(() => import("pages/landing/about-us/AboutUs"));
const ContactUs = lazy(() => import("pages/landing/contact-us/ContactUs"));
const Landing = lazy(() => import("layouts/landing/Landing"));

const LatestUpdates = lazy(() =>
  import("pages/landing/latest-updates/LatestUpdates")
);
const Contest = lazy(() => import("pages/landing/contest/Contest"));
const Videos = lazy(() => import("pages/landing/videos/Videos"));
const ViewSingleContest = lazy(() =>
  import("pages/landing/view-single-contest/ViewSingleContest")
);
const TermsAndConditions = lazy(() =>
  import("pages/landing/terms-and-conditions/TermsAndConditions")
);
const ResetPassword = lazy(() =>
  import("pages/landing/resetPassword/ResetPassword")
);
const Login = lazy(() => import("pages/landing/login/Login"));
const VoterSignUp = lazy(() => import("pages/landing/voterSgnUp"));
const ContestantSignup = lazy(() => import("pages/landing/contestantSignup"));
const ForgotPassword = lazy(() => import("pages/landing/forgotPassword"));
const Verification = lazy(() => import("pages/landing/verificaton"));
const LegalNotice = lazy(() => import("pages/landing/legal-notice"));
const PrivacyPolicy = lazy(() => import("pages/landing/privacy-policy"));

const CookiePolicy = lazy(() => import("pages/landing/cookie-policy"));

const LandingRoutes = () => {
  return (
    <Routes>
      <Route path={LANDING_ROUTE} element={<Landing />}>
        <Route index element={<Home />} />
        <Route path={HOME_ROUTE} element={<Home />} />
        <Route path={ABOUT_US_ROUTE} element={<AboutUs />} />
        <Route path={LATEST_UPDATES_ROUTE} element={<LatestUpdates />} />
        <Route path={CONTACT_US_ROUTE} element={<ContactUs />} />
        <Route path={CONTEST_ROUTE} element={<Contest />} />
        <Route path={VIDEOS_ROUTE} element={<Videos />} />
        <Route path={VIEW_SINGLE_CONTEST} element={<ViewSingleContest />} />
        <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
        <Route path={LOGIN_ROUTE} element={<Login />} />
        <Route path={VOTER_SIGNUOP_ROUTE} element={<VoterSignUp />} />
        <Route path={CONTESTANT_SIGNUOP_ROUTE} element={<ContestantSignup />} />
        <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
        <Route path={VERIFICATION_ROUTE} element={<Verification />} />
        <Route
          path={TERMS_AND_CONDTIONS_ROUTE}
          element={<TermsAndConditions />}
        />
        <Route path={LEGAL_NOTICE_ROUTE} element={<LegalNotice />} />
        <Route path={PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
        <Route path={COOKIE_POLICY_ROUTE} element={<CookiePolicy />} />
      </Route>
    </Routes>
  );
};

export default LandingRoutes;
